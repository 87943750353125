import {
  AfterContentInit,
  Component,
  ContentChild,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { ParseMSOptions, UtilService } from '@app/_services/util.service';
import { AppService } from '@app/app.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { format } from 'date-fns/esm';
import en from 'date-fns/esm/locale/en-US';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { UserSettingsQuery } from 'timeghost-api';

import { TimeInputCustomControlDirective } from './time-input-custom.directive';

export class TimeParseResult {
  hour: number;
  minute: number;
}
export const MODEL_DATE_FORMAT = 'YYYY-MM-DD';
export const MODEL_TIME_FORMAT = 'HH:mm';

const MODEL_TIME_DURATION_FORMAT: ParseMSOptions = { showNegative: false, showSeconds: false } as any;
@UntilDestroy()
@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeInputComponent),
      multi: true,
    },
  ],
  host: {
    '(click)': 'onClick($event)',
  },
})
export class TimeInputComponent implements ControlValueAccessor, OnInit, AfterContentInit {
  @Input() allowExceedMax: boolean = false;
  private _control: UntypedFormControl = new UntypedFormControl(null, { updateOn: 'blur' });
  private _date: Date = new Date();
  propagateChange: Function = () => {};
  propagateTouched: Function = () => {};
  registerOnChange(fn: (_: any) => void): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  get control(): UntypedFormControl {
    return this._control;
  }

  get date(): Date {
    return this._date;
  }
  @Input() displayFormat = 'LT';
  @Input() inputPlaceholder = 'time.input';
  @Input() formControlName: string;
  @Input() inputId: string;
  @Input() inputClass: string;

  @ContentChild(TimeInputCustomControlDirective)
  customInputTemplate: TimeInputCustomControlDirective;
  get defaultDisplayFormat() {
    return this.userSettingsQuery.getValue().settings.timeFormat24h ? 'HH:mm' : 'hh:mm a';
  }
  constructor(
    private userSettingsQuery: UserSettingsQuery,
    private appService: AppService,
    private elementRef: ElementRef<HTMLElement>,
  ) {}
  get name() {
    return this.formControlName;
  }
  ngOnInit() {
    this._control.valueChanges.pipe(untilDestroyed(this)).subscribe((value: any) => {
      this.handleInputChange(value);
    });
    const displayFormat = this.displayFormat;
    if (displayFormat === 'LT' || (displayFormat as any) == false)
      this.userSettingsQuery
        .select((x) => x.settings.timeFormat24h)
        .pipe(
          distinctUntilChanged(),
          map((x) => (x ? 'HH:mm' : 'hh:mm a')),
          untilDestroyed(this),
        )
        .subscribe((x) => ((this.displayFormat = x), this.handleInputChange(this._control.value)));
    else if (displayFormat === 'duration') this.handleInputChange(this._control.value);
  }
  setDisabledState(isDisabled: boolean) {
    this.control[isDisabled ? 'disable' : 'enable']();
  }
  ngAfterContentInit() {
    this.customInputTemplate?.inputElement$.pipe(filter((x) => !!x?.nativeElement)).subscribe((el) => {
      if (el.nativeElement) el.nativeElement.type = this.platform ? 'time' : 'text';
    });
  }
  @Input('roundFn')
  roundFn?: (date: Date) => Date;
  writeValue(_time: string) {
    const t = this.parseTime(_time);
    if (!t) return;
    const displayFormat = this.displayFormat || this.defaultDisplayFormat;
    if (displayFormat === 'duration') {
      return this.control.setValue(
        UtilService.parseMS((t.hour * 60 + t.minute) * 60 * 1000, MODEL_TIME_DURATION_FORMAT),
        { emitEvent: false },
      );
    }
    this.date.setHours(t.hour, t.minute, 0, 0);
    this.roundFn?.(this.date);
    this.control.setValue(format(this.date, displayFormat, { locale: en }), {
      emitEvent: false,
    });
  }
  handleInputChange(value: string) {
    const t = this.parseTime(value);
    if (!t) return;
    const displayFormat = this.displayFormat || this.defaultDisplayFormat;
    if (displayFormat !== 'duration') {
      this.date.setHours(t.hour, t.minute, 0, 0);
      this.roundFn?.(this.date);
    }
    const nextMs = displayFormat === 'duration' && (t.hour * 60 + t.minute) * 60 * 1000;
    const modelValue =
      displayFormat === 'duration'
        ? UtilService.parseMS(nextMs, MODEL_TIME_DURATION_FORMAT)
        : format(this.date, MODEL_TIME_FORMAT);
    const nextValue =
      displayFormat === 'duration'
        ? UtilService.parseMS(nextMs, MODEL_TIME_DURATION_FORMAT)
        : format(this.date, displayFormat, { locale: en });
    this.control.setValue(nextValue, {
      emitEvent: false,
    });
    this.propagateChange(modelValue);
    this.propagateTouched(modelValue);
  }

  parseTime(timeStr: string): TimeParseResult {
    if (!timeStr) return null;
    if (this.displayFormat === 'duration') {
      const [hour, minute] = timeStr.split(':', 2).map((d) => (typeof d === 'string' ? Number(d) : d));
      return {
        hour,
        minute: minute ?? 0,
      };
    }
    if (timeStr.indexOf(':') === -1 && timeStr?.replace(/(a|p)(m)?$/, '')?.length === 3) {
      timeStr = timeStr.insert(0, 0);
    }
    let rxResult;
    let meridian: string = null;
    if (/a(m)?$/i.test(timeStr)) {
      meridian = 'am';
    } else if (/p(m)?$/i.test(timeStr)) {
      meridian = 'pm';
    }

    const rx = /\d{1,2}/g;
    const results = [];

    while ((rxResult = rx.exec(timeStr)) !== null) {
      results.push(rxResult[0]);
    }
    let hour = results[0] ? parseInt(results[0], 10) : 0;
    if (meridian === 'pm') {
      if (hour < 12) hour += 12;
    } else if (meridian === 'am') {
      if (hour === 12) hour -= 12;
    }
    const minute = results[1] ? parseInt(results[1], 10) : 0;
    return {
      hour,
      minute,
    };
  }
  onClick(ev: Event) {
    // if (!this.appService.isMobile) return;
    // ev.preventDefault();
    // this._timePicker.open();
    // this._timePicker.timeChanged
    //   .asObservable()
    //   .pipe(debounceTime(500), distinctUntilChanged())
    //   .subscribe((time) => {
    //     this.handleInputChange(time);
    //   });
  }
  get platform() {
    return this.appService.platform;
  }
  testNative(ev: Event) {
    if (!this.platform) return;
    ev.preventDefault();
  }
  private selectInput(ev: Event) {
    const el: HTMLElement = this.elementRef?.nativeElement;
    if (!el) {
      return;
    }
    const input = el.querySelector('input');
    if (input) {
      ev.preventDefault();
      input.select();
    }
  }
  @HostListener('focus', ['$event'])
  @HostListener('click', ['$event'])
  onPreviewClick(ev: Event) {
    this.selectInput(ev);
  }
  isMobile$ = this.appService.isMobile$;
}
